<template>
    <div>
      <div>
        <!-- <div class="input-group mb-3">
          <input type="text" class="form-control" placeholder="Search by title" v-model="title" />
          <div class="input-group-append">
            <button class="btn btn-outline-secondary" type="button" @click="searchTitle">
              Search
            </button>
          </div>
        </div> -->

        <div class="form-group">
          <label for="exampleFormControlSelect1"
            ><h5 style="width: 200px">
              <i class="bi bi-person-plus"></i> 출결 날짜별 통계
            </h5></label
          >
        </div>
      </div>

      <!-- <div>  
        <select
        class="form-control"
        id="gradegroup"
        name="gradegroup"
        v-model="gradegroup"
        @change="getGradeGroup()"
        v-show="this.role < 3"
      >
        <option id="" value="00">조회 대상을 선택하세요.</option>         
        <option
          v-for="gradelist in gradegrouplist"
          :key="gradelist.grade"
          v-bind:value="gradelist.grade"
          :id="gradelist.grade"
        >
          {{ gradelist.gradename }}
        </option>
          </select>    
        </div>  -->
        <p></p>      
   
       <!--  재적 인원 -->
      <div class="card">
        <h5 class="card-header"> 재적인원</h5>
        <div class="card-body">
          <h5 class="card-title">{{gradefullname}}</h5>
          <p class="card-text">
            <table class="table table-bordered">
                <thead>
                  <tr style="text-align: center;">
                    <th scope="col">남</th>
                    <th scope="col">여</th>
                    <th scope="col">합계</th>
                  </tr>
                </thead>
                <tbody>
                  <tr style="text-align: center;">
                    <td>{{enrollList.men_cnt}}</td>
                    <td>{{enrollList.women_cnt}}</td>
                    <td>{{enrollList.totalcnt}}</td>
                  </tr>
                </tbody>
              </table>   
          </p>
        </div>
      </div>
     <div style="height: 20px;"></div>
  <!--  출석 인원 -->

  <div class="card">
    <h5 class="card-header">출석인원</h5>
    <div class="card-body">
      <h5 class="card-title"></h5>
      <p class="card-text">
        <table class="table table-bordered">
            <thead>
              <tr style="text-align: center;">
                <th scope="col">날짜</th>
                <th scope="col">남</th>
                <th scope="col">여</th>
                <th scope="col">합계</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in attendLists" :key="item.attenddate"  style="text-align: center;">
                <th scope="row">{{ item.attenddate}}</th>
                <td>{{ item.att1}}</td>
                <td>{{ item.att2}}</td>
                <td>{{ item.atttotalcnt}}</td>
              </tr>
            </tbody>
          </table>       
      </p>
    </div>
  </div>
    </div>
</template>      

<script>
    import manageService from "../services/manageService";
   
    //import $ from "jquery";
    
    export default {
      name: "list",
      component: {},
      data() {
        return {
            enrollList: {},  
            attendLists : {},          
            pageTotalCount: 0,
            page: 15,
            holiday: "",
            currentDept: "",            
            id: this.$store.state.id,
            grade: this.$store.state.grade,
            phone: this.$store.state.phone,
            name: this.$store.state.name,
            teacherid: this.$store.state.teacherid,
            studentid: this.$store.state.studentid,
            role: this.$store.state.role,
            token: this.$store.state.token,    
            classid: this.$store.state.classid,
            gradefullname : "",
            gradegrouplist : {},
            gradegroup : "00",               

        };
      },
      created() {
        //this.initGetMemberList(this.currentDept);
      },
      computed: {
      },
      methods: {
        getGradeGroup(){
        if(this.gradegroup === "00") return;
        this.getAttendList(this.gradegroup);
        this.getenrolled(this.gradegroup);
      },
        getGradeGroupList(){ // 학년 반을 선택하세요.

          const data = {              
              classid: this.classid,
          };

        manageService
        .getGradeListAll(data)
        .then((response) => {
          this.gradegrouplist = response.data;
        })
        .catch((e) => {
          console.log(e);
        });

      },         

        getenrolled(){     //재적인원

          const data = {
                  grade: this.grade,
                  classid: this.classid,
                  studentid: this.studentid,
                };
            manageService
            .getenrolled(data)
            .then((response) => {

            this.enrollList = response.data[0];   
       
            })
            .catch((e) => {
            console.log(e);
            });
        },

        getAttendList() {    //전체 리스트 가져오기
          const data = {              
              grade: this.grade,
              studentid: this.studentid,
              classid: this.classid,
          };
        
          manageService
              .getenrolllist(data)
              .then((response) => {
              this.attendLists = response.data;

            })
            .catch((e) => {
            console.log(e);
            });
  }, 

    },   
      mounted() {
        this.getenrolled();
        this. getAttendList();
        this.getGradeGroupList();
      },
    };
    </script>
    
    <style scoped>
    .list {
      text-align: left;
      max-width: 750px;
      margin: auto;
    }
    .done {
      background: oldlace;
    }
    .card-header {
      background-color: rgb(0 123 255 / 22%);
    }
    </style>
    